import { gql, useLazyQuery } from '@apollo/client';
import { UserData, userObj } from 'sop-commons/src/client';

import { roleObj } from 'ui-components/DashboardMenu';

const GET_JOBS = gql`
  query GET_JOBS {
    user {
      entity {
        roles {
          createdAt
          createdBy
          name
          status
          color
          eid
          updatedAt
        }
      }
    }
  }
`;

export const useFetchRoles = () => {
  const userData = userObj();
  const [getRoles, { loading }] = useLazyQuery(GET_JOBS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { user: UserData }) => {
      const roles = data?.user?.entity?.roles;
      userObj({ ...userData, entity: { ...userData?.entity, roles } });
      if (roles && roles.length) {
        let roleWithColor: Record<string, string> = {};
        roles.forEach((role) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error?.message);
    },
  });

  const fetchRoles = () => {
    getRoles();
  };

  return { loading, fetchRoles };
};

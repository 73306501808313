import React, {
  cloneElement,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalFooter,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthRole } from 'authorization';
import { toArray } from 'utils/utils';
import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';

import { roleObj } from '../DashboardMenu';
import PermissionLevelInput from './PermissionLevelInput';
import InviteLocationSelect from './InviteLocationSelect';
import { IFormInput } from './invite.types';
import LocationChips from './LocationChips';
import {
  INVITE_USER_QUERY,
  InvitedUser,
  InviteResponse,
  InviteVariable,
} from './invite.graphql';
import { ActionButton } from '../Confirm';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { v4 as uuid } from 'uuid';
import { getImage } from 'utils';
import { UserType } from 'types';
import { useFetchRoles } from 'hooks';

export interface AddTempLO {
  label: string;
  value: string;
  authRole: AuthRole.LOCATION_OWNER;
  eid: string;
  locations: [];
  name: string;
  role: string;
  type: UserType;
  profilePic: string;
}

interface IProps {
  inviteOnlyFor?: AuthRole;
  shouldRefetch?: (data: Omit<InvitedUser, 'inviteUrl' | 'code'>) => void;
  tempMemberAdd?: (member: AddTempLO) => void;
  onClose: () => void;
  rootLocation?: {
    eid: string;
    name: string;
  };
  /** Used to disable location select if passing default location value which cannot be changed. Example is inviting location owner for a specific location, there another location cannot be added */
  shouldDisableLocationSelect?: boolean;
  /** Used to hide the 'cross' icon from selected location chips. Specifically used when inviting location owner for a specific location and that location must not be removed as location owner is being added for that location only */
  shouldPreventLocationRemoval?: boolean;
  shouldDisableInviteSetupNowBtn?: boolean;
  customActionBtn?: React.ReactNode;
  disableAllPermissions?: boolean;
}

const InviteUserForm: FC<IProps> = ({
  inviteOnlyFor,
  onClose,
  rootLocation,
  tempMemberAdd,
  shouldRefetch,
  shouldDisableLocationSelect,
  shouldPreventLocationRemoval,
  shouldDisableInviteSetupNowBtn,
  customActionBtn,
  disableAllPermissions,
}) => {
  const { t } = useTranslation(['common', 'invite', 'role']);
  const { control, getValues, setValue, handleSubmit } =
    useFormContext<IFormInput>();
  const { loading: loadingRoles, fetchRoles } = useFetchRoles();
  const roleColorByName = useReactiveVar<Record<string, string>>(roleObj);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const roleList = useMemo(() => {
    return Object.keys(roleColorByName || {}).map((key) => {
      return { label: key, value: key };
    });
  }, [roleColorByName]);

  useEffect(() => {
    if (roleList?.length === 0) {
      fetchRoles?.();
    }
  }, []);

  const [inviteUser] = useMutation<InviteResponse, InviteVariable>(
    INVITE_USER_QUERY,
    {
      onCompleted: (response) => {
        setValue('inviteUrl', response.inviteUser.inviteUrl);
        setValue('invitedUserId', response.inviteUser.eid);
        setValue('currentStep', 'shareTabs');
        shouldRefetch?.({
          eid: response.inviteUser.eid,
          name: response.inviteUser.name,
        });
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('error'),
          description: error?.message,
        });
      },
    }
  );

  const onPermissionChange = useCallback(
    (nextValue: AuthRole) => {
      if (
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(nextValue) &&
        rootLocation
      ) {
        setValue(
          'location',
          {
            id: rootLocation.eid,
            label: rootLocation.name,
            value: rootLocation.name,
          },
          {
            shouldValidate: true,
          }
        );
      } else {
        setValue('location', []);
      }
    },
    [rootLocation]
  );

  const submitInviteForm = async (values: IFormInput) => {
    deployEvent(AmplitudeEventNames.MEMBER_SEND_INVITE, {
      name: values.name,
      job: values.job?.value,
      permissionLevel: values.permissionLevel,
      location: values.location,
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      method: values?.setupLater
        ? t('invite:inviteAndSetupLater')
        : t('invite:inviteAndSetupNow'),
      member_id: getValues('invitedUserId'),
    });
    await inviteUser({
      variables: {
        input: {
          name: values.name?.trim(),
          authRole: values.permissionLevel!,
          role: values.job?.value,
          branchIds: toArray(values.location)
            .map((it) => it.id)
            .filter(Boolean),
        },
      },
    });
  };

  const inviteAndSetupNow = async (values: IFormInput) => {
    setValue('setupLater', false);
    await submitInviteForm(values);
  };

  const inviteAndSetupLater = async (values: IFormInput) => {
    deployEvent(AmplitudeEventNames.INVITE_AND_SET_UP_LATER);
    setValue('setupLater', true);
    await submitInviteForm(values);
  };

  const handleCustomAction = () => {
    let tempId = uuid();
    tempMemberAdd?.({
      eid: `create-temp-${tempId}`,
      role: getValues('job')?.value,
      label: getValues('name'),
      value: `create-temp-${tempId}`,
      profilePic: getImage('', getValues('name')),
      authRole: AuthRole.LOCATION_OWNER,
      locations: [],
      name: getValues('name'),
      type: 'user',
    });
    onClose();
  };

  return (
    <>
      <ModalBody>
        <Box>
          <Controller
            name='name'
            control={control}
            defaultValue=''
            rules={{
              required: t('invite:validation.name_required'),
              pattern: {
                value: /^\S.*\S$|^\S$/,
                message: t('invite:validation.invalid_name'),
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel fontSize='14px' fontWeight='600' htmlFor='name'>
                  {t('invite:inviteName')}
                </FormLabel>
                <FormInput
                  {...field}
                  size='lg'
                  id='name'
                  placeholder={t('invite:enter_name')}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            name='job'
            control={control}
            rules={{ required: t('invite:validation.jobRequired') }}
            render={({ field, fieldState }) => (
              <FormControl mt={4} isInvalid={!!fieldState.error}>
                <FormLabel fontSize='14px' fontWeight='600' htmlFor='job'>
                  {t('invite:inviteJob')}
                </FormLabel>
                <Dropdown
                  size='lg'
                  id='job'
                  placeholder={t('invite:inviteSelectJob')}
                  {...field}
                  isLoading={loadingRoles}
                  options={roleList}
                  isDisabled={roleList?.length === 0 || loadingRoles}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            name='permissionLevel'
            control={control}
            defaultValue={undefined}
            rules={{ required: t('invite:validation.permissionLevelRequired') }}
            render={({ field, fieldState }) => (
              <FormControl
                mt={4}
                as='fieldset'
                isReadOnly={!!inviteOnlyFor}
                isInvalid={!!fieldState.error}
              >
                <FormLabel as='legend'>
                  <Flex gap={4} alignItems='center'>
                    <Text fontSize='14px' fontWeight='600'>
                      {t('invite:invitePermissionLevel')}
                    </Text>
                    <Tooltip
                      label={t('invite:invitePermissionLevelInfo')}
                      placement='bottom'
                      hasArrow
                      borderRadius='6px'
                      padding='5px 10px'
                    >
                      <InfoOutlineIcon cursor='pointer' />
                    </Tooltip>
                  </Flex>
                </FormLabel>
                <PermissionLevelInput
                  {...field}
                  disableAllPermissionLevels={disableAllPermissions}
                  onChange={(nextValue: AuthRole) =>
                    callAll(field.onChange, onPermissionChange)(nextValue)
                  }
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            name='location'
            control={control}
            rules={{ required: t('invite:validation.locationRequired') }}
            render={({ field, fieldState }) => (
              <FormControl mt={4} isInvalid={!!fieldState.error}>
                <FormLabel fontSize='14px' fontWeight='600' htmlFor='location'>
                  {t('invite:inviteLocation')}
                </FormLabel>

                <InviteLocationSelect
                  rootLocation={rootLocation}
                  shouldDisableLocationSelect={shouldDisableLocationSelect}
                  {...field}
                />

                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>

                <LocationChips
                  shouldPreventLocationRemoval={shouldPreventLocationRemoval}
                />
              </FormControl>
            )}
          />
        </Box>
      </ModalBody>
      <ModalFooter gap='10px'>
        {customActionBtn ? (
          React.Children.map(customActionBtn, (child) => {
            if (React.isValidElement(child)) {
              return cloneElement(child, {
                onClick: handleSubmit(handleCustomAction),
              });
            }
            return child;
          })
        ) : (
          <>
            <ActionButton
              size='lg'
              variant='outline'
              actionFn={handleSubmit(inviteAndSetupLater)}
              fontSize='15px'
              fontWeight='700'
            >
              {t('invite:inviteAndSetupLater')}
            </ActionButton>

            <ActionButton
              size='lg'
              colorScheme='blue'
              disabled={shouldDisableInviteSetupNowBtn}
              actionFn={handleSubmit(inviteAndSetupNow)}
              fontSize='15px'
              fontWeight='700'
            >
              {t('invite:inviteAndSetupNow')}
            </ActionButton>
          </>
        )}
      </ModalFooter>
    </>
  );
};

InviteUserForm.displayName = 'ui-components/InviteUserNew/InviteUserForm';

export default InviteUserForm;
